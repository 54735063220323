<template>
	<v-app>
		<main class="fullh">
			<v-container fluid fill-height class="loginOverlay">
				<v-layout flex align-center justify-center>
					<v-flex xs12 sm4>
						<div style="text-align: center;" class="mb-2">
							<img src="../assets/logo-sc-blanco.svg" />
						</div>
						<v-card elevation="0">
                            <span class="tittleUpdate">{{ $t('update.txtTitle') }}</span>
							<v-card-text class="pt-4">
                                <p class="textUpdate">
                                    {{ $t('update.txtUpdate') }}
                                </p>
							</v-card-text>
						</v-card>
						<v-btn
							block
							class="mt-5 add-button"
							color="orange accent-4"
							dark
							depresseds
							:disabled="!updateExists"
                            @click.prevent="refreshApp"
						>
							<v-icon medium left>
								mdi-reload
							</v-icon>
							Recargar app
						</v-btn>
					</v-flex>
				</v-layout>
			</v-container>
		</main>
	</v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	name: "Update",
	data() {
		return {};
	},
    methods: {
        ...mapMutations({
            update: 'update',
            updateDowloadM: 'updateDowload'
        }),
        refreshApp() {
            this.update(false);
            this.updateDowloadM(false);
			if (!this.registration || !this.registration.waiting) return;
			this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
		},
    },
    computed: {
        ...mapState({
            updateExists: (state) => state.updateExists,
            registration: (state) => state.registration,
            updateDowload: (state) => state.updateDowload,
        }),
        updateDowload: {
            get () {
                return this.$store.state.updateDowload
            },
            set (val) {
                this.updateDowloadM(val);
            }
        }
    },
    mounted () {
        if (!this.updateDowload) {
            // Return
            this.$router.back();
        }
    }
};
</script>

<style scoped>
.fullh {
	height: 100%;
	background-color: #4527a0;
}
.tittleUpdate {
    padding: 15px;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 19px;
    color: #37474F !important;
}

.textUpdate {
    text-align: center;
}

</style>
